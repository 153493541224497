import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  display: inline-flex;
  height: 40px;
  width: 40px;

  @media screen and (min-width: 768px) {
    height: 28px;
    width: 28px;
  }
`;

function IconLoveIt() {
  return (
    <SVG width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 25.4847L2.81404 13.818C1.83093 12.8357 1.18207 11.5687 0.959464 10.1969C0.736863 8.82507 0.95183 7.41798 1.57388 6.17518V6.17518C2.04294 5.23732 2.72814 4.42433 3.57301 3.80321C4.41789 3.18209 5.39826 2.77061 6.43334 2.60267C7.46843 2.43473 8.5286 2.51515 9.52651 2.83728C10.5244 3.15942 11.4315 3.71406 12.173 4.45551L14 6.28134L15.827 4.45551C16.5686 3.71406 17.4757 3.15942 18.4736 2.83728C19.4715 2.51515 20.5317 2.43473 21.5667 2.60267C22.6018 2.77061 23.5822 3.18209 24.4271 3.80321C25.2719 4.42433 25.9571 5.23732 26.4262 6.17518C27.0474 7.41749 27.262 8.82376 27.0397 10.1948C26.8173 11.5659 26.1692 12.8322 25.1872 13.8145L14 25.4847Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
}

export default IconLoveIt;

import React from 'react';

function IconInfo() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C8.02218 0 6.08879 0.58649 4.44429 1.6853C2.7998 2.78412 1.51808 4.3459 0.761202 6.17316C0.00432523 8.00043 -0.193708 10.0111 0.192144 11.9509C0.577996 13.8907 1.5304 15.6725 2.92893 17.0711C4.32745 18.4696 6.10928 19.422 8.04909 19.8078C9.9889 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34871 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10.2083 4.16667C10.4556 4.16667 10.6972 4.23998 10.9028 4.37733C11.1084 4.51468 11.2686 4.7099 11.3632 4.93831C11.4578 5.16672 11.4825 5.41805 11.4343 5.66053C11.3861 5.90301 11.267 6.12573 11.0922 6.30055C10.9174 6.47537 10.6947 6.59442 10.4522 6.64265C10.2097 6.69088 9.95838 6.66613 9.72998 6.57152C9.50157 6.47691 9.30634 6.31669 9.16899 6.11113C9.03164 5.90557 8.95833 5.66389 8.95833 5.41667C8.95833 5.08515 9.09003 4.7672 9.32445 4.53278C9.55887 4.29836 9.87681 4.16667 10.2083 4.16667ZM12.0833 15.4167H8.75C8.52898 15.4167 8.31702 15.3289 8.16074 15.1726C8.00446 15.0163 7.91666 14.8043 7.91666 14.5833C7.91666 14.3623 8.00446 14.1504 8.16074 13.9941C8.31702 13.8378 8.52898 13.75 8.75 13.75H9.375C9.43025 13.75 9.48324 13.728 9.52231 13.689C9.56138 13.6499 9.58333 13.5969 9.58333 13.5417V9.79167C9.58333 9.73641 9.56138 9.68342 9.52231 9.64435C9.48324 9.60528 9.43025 9.58333 9.375 9.58333H8.75C8.52898 9.58333 8.31702 9.49553 8.16074 9.33925C8.00446 9.18297 7.91666 8.97101 7.91666 8.75C7.91666 8.52899 8.00446 8.31702 8.16074 8.16074C8.31702 8.00446 8.52898 7.91667 8.75 7.91667H9.58333C10.0254 7.91667 10.4493 8.09226 10.7618 8.40482C11.0744 8.71738 11.25 9.1413 11.25 9.58333V13.5417C11.25 13.5969 11.2719 13.6499 11.311 13.689C11.3501 13.728 11.4031 13.75 11.4583 13.75H12.0833C12.3043 13.75 12.5163 13.8378 12.6726 13.9941C12.8289 14.1504 12.9167 14.3623 12.9167 14.5833C12.9167 14.8043 12.8289 15.0163 12.6726 15.1726C12.5163 15.3289 12.3043 15.4167 12.0833 15.4167Z"
        fill="#333333"
      />
    </svg>
  );
}

export default IconInfo;

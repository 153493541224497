import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.span`
  display: inline-block;
  width: 3em;
  .background {
    fill: var(--background-color, #f7e4e9);
  }

  .color {
    stroke: var(--color, #ad0024);
  }

  flex: 0 0 1em;
  margin: 0;
  transition: transform 0.25s ease-in-out;
`;

const SVG = styled.svg`
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
`;

function IconSuccess() {
  return (
    <IconContainer>
      <SVG viewBox="0 0 99 99" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(1,0,0,1,-450,-253)">
          <g>
            <circle cx="499.967" cy="302.155" r="48.997" className="background" />
            <g transform="matrix(1,0,0,1,-0.333931,0.209256)">
              <path
                d="M476.582,304.83L495.871,320.693L524.02,283.199"
                className="color"
                style={{ fill: 'none', strokeWidth: '8.5px' }}
              />
            </g>
          </g>
        </g>
      </SVG>
    </IconContainer>
  );
}
export default IconSuccess;

import React, { PropsWithChildren, useEffect } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import styled from 'styled-components';
import Footer from './Footer';
import Header from './Header';
import { IConfig, IMicrositeImages, IProductCategory, IProductInfo } from '../../types';
import { useAppContext } from './Context';
import Loader from './Loader';
import ErrorPopup from './ErrorPopup';

const LayoutContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: calc(100vh - 80px);
  @media screen and (min-width: 768px) {
    height: 100vh;
  }
`;

interface ILayout extends PropsWithChildren {
  config: IConfig;
  categories: IProductCategory[];
  images?: IMicrositeImages;
  products?: IProductInfo[];
}

function Layout({ children, categories, config, images, products }: ILayout) {
  const router = useRouter();
  const {
    actions,
    state: { isLoading, isUpdatingCart, errorPopup, cart },
  } = useAppContext();

  useEffect(() => {
    actions.setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // ResortPass loader for certain pages
    const completeHandler = (path) => {
      actions.setIsLoading(false);
      if (actions.getPreviousRoute() === '/confirm?success') {
        actions.clearState();
        actions.clearCartStorage();

        if (path === '/confirm') {
          router.push('/');
        }
      }

      setTimeout(() => {
        actions.setIsLoading(false);
      }, 1500);
    };

    const startHandler = () => {
      actions.setIsLoading(true);
      actions.setPreviousRoute(router.asPath);
    };
    router.events.on('routeChangeStart', startHandler);
    router.events.on('routeChangeComplete', completeHandler);
    window.addEventListener('beforeunload', () => {
      actions.setIsLoading(true);
    });

    return () => {
      router.events.off('routeChangeComplete', completeHandler);
      router.events.off('routeChangeStart', startHandler);
    };
  });

  useEffect(() => {
    if (products && products.length) {
      const productsAndChildren = products
        .flatMap((p) => (p.children ? [p, ...p.children] : [p]))
        .filter((p) => p.id);

      actions.setProductSeatings(productsAndChildren);
    }
  }, [products, actions, cart.items]);

  return (
    <div className="wl-template">
      <LayoutContainer>
        <Head>
          <title>{config ? config.name : ''} | ResortPass</title>        
          <meta name="robots" content="noindex,nofollow" key="robots" />
        </Head>
        {(isLoading || isUpdatingCart) && <Loader images={images} />}
        <Header config={config} categories={categories} images={images} />
        {children}
        <Footer />
        {errorPopup.open && <ErrorPopup {...errorPopup} />}
      </LayoutContainer>
    </div>
  );
}
Layout.defaultProps = { images: undefined, products: undefined };

export default Layout;

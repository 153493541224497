import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const IconContainer = styled.div`
  display: inline-block;
  flex: 0 0 1em;
  transition: transform 0.25s ease-in-out;
  height: 24px;
  width: 24px;
`;

const SVG = styled.svg`
  display: inline-block;
  width: 100%;
  height: 100%;

  .stroke {
    stroke: var(--color, #000);
  }

  svg {
    width: 100%;
  }
`;

interface IIconTimeProps extends ComponentProps<any> {}

function IconTime({ className }: IIconTimeProps) {
  return (
    <IconContainer>
      <SVG
        viewBox="0 0 26 26"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 1.5,
        }}
        className={classnames([className])}
      >
        <path
          d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
          stroke="#333333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 9.53198V13.656L16.844 17.5"
          stroke="#333333"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SVG>
    </IconContainer>
  );
}

export default IconTime;

import React from 'react';
import styled from 'styled-components';
import { IconCart } from '@common/icons';
import { useAppContext } from './Context';

const IconButton = styled.div``;

interface ICart {
  count: number;
}

function Cart({ count = 0 }: ICart) {
  const { actions } = useAppContext();

  return (
    <div id="CartContainer">
      <IconButton onClick={() => actions.toggleCart()}>
        <IconCart />
        <div id="count">{count}</div>
      </IconButton>
    </div>
  );
}

export default Cart;

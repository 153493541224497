import React from 'react';
import { IconLogo } from '@common/icons';

function Footer() {
  return (
    <div id="FooterContainer">
      <div id="content">
        <span>&copy; {new Date().getFullYear()} ResortPass, Inc. &nbsp;</span>
        <span>
          <a
            href="https://www.resortpass.com/terms-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>{' '}
          &middot;
          <a
            href="https://www.resortpass.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </span>
      </div>
      <a id="powered" href="https://www.resortpass.com" target="_blank" rel="noopener noreferrer">
        <IconLogo /> Powered by ResortPass
      </a>
    </div>
  );
}

export default Footer;

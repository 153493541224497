import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { buildQueryParams } from '@utils';
import { IConfig, IMicrositeImages } from '../../types';

const InitialsLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none;
  // disable highlighting on touch devices
  -webkit-user-select: none;
  background: #272e30;
  color: #ffffff;
  width: 32px;
  height: 32px;
  max-width: 100%;
  padding: 6px;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.2px;
  border-radius: 4px;
`;

const LogoLink = styled.a`
  height: auto !important;
`;

interface ILogoProps {
  config: IConfig;
  images?: IMicrositeImages;
}

interface IInitialsLogoProps {
  config: IConfig;
}

function InitialsLogo({ config }: IInitialsLogoProps) {
  const initials = config?.name
    ?.split(' ')
    .slice(0, 2)
    .map((word) => word[0])
    .map(([initial]) => initial.toUpperCase())
    .join(' ');

  return <InitialsLogoWrapper>{initials}</InitialsLogoWrapper>;
}

function Logo({ config, images }: ILogoProps) {
  const router = useRouter();
  const params =
    router.query.confirmation || router.query.date
      ? `?${buildQueryParams(
          {
            preview: router.query.preview,
            confirmation: router.query.confirmation,
            date: router.query.date,
          },
          {},
        )}`
      : '';
  return (
    <Link
      href={config?.home?.url ? `${config.home.url}?${params}` : `/${params}`}
      role="link"
      passHref={!images?.logo}
    >
      {images?.logo ? (
        <div style={{ height: '100%' }}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            style={{ maxWidth: '100%', height: '100%' }}
            loading="eager"
            id="logo"
            alt={config?.name}
            src={images?.logo}
          />
        </div>
      ) : (
        <LogoLink>
          <InitialsLogo config={config} />
        </LogoLink>
      )}
    </Link>
  );
}
Logo.defaultProps = { images: undefined };

export default Logo;

import React from 'react';
import styled from 'styled-components';

const CrumbsContainer = styled.span`
  display: inline;

  .divider {
    display: inline-block;
    margin: 0 1em;
    color: var(--text-color, #777);
  }

  a:link,
  a:visited,
  a:hover,
  a:focus,
  a:active {
    color: var(--text-color, #777);
    text-decoration: none;
  }

  a.active {
    color: var(--heading-color, #333);
    font-weight: 600;
  }
  a,
  span {
    font-size: 0.9em;
  }
`;

interface ICrumbsProps {
  children: React.ReactNode;
}

function Crumbs({ children }: ICrumbsProps) {
  return <CrumbsContainer>{children}</CrumbsContainer>;
}

export default Crumbs;

import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import styled from 'styled-components';
import Button from '@common/Button';
import { IconAlert } from '@common/icons';
import { useAppContext } from './Context';

const ErrorPopupContainer = styled.div`
  font-weight: 600;
  padding: 40px;
  font-family: 'Proxima Nova', sans-serif;

  text-align: center;
`;
const ErrorMessage = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;
  margin-top: 24px;
  margin-bottom: 28px;
`;

const ErrorIcon = styled.div`
  width: 88px;
  height: 88px;
  background: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

const ErrorButton = styled(Button)`
  padding: 18px 39px;
  font-size: 17px;
`;

interface IErrorPopsProps {
  open: boolean;
  text?: string;
  title?: string;
  callback?: () => void;
}
// eslint-disable-next-line
function ErrorPopup({ open, text, title, callback }: IErrorPopsProps) {
  const { actions } = useAppContext();
  const closeModal = () => {
    const cb = callback;
    actions.setErrorPopup({
      open: false,
      text: undefined,
      callback: undefined,
    });
    if (cb) cb();
  };

  return (
    <Popup open={open} closeOnDocumentClick onClose={closeModal}>
      <ErrorPopupContainer>
        <ErrorIcon>
          <IconAlert />
        </ErrorIcon>
        <ErrorMessage>{text}</ErrorMessage>
        <ErrorButton onClick={closeModal}>OK</ErrorButton>
      </ErrorPopupContainer>
    </Popup>
  );
}
ErrorPopup.defaultProps = {
  title: undefined,
  text: undefined,
  callback: undefined,
};
export default ErrorPopup;

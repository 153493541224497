import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.span`
  display: inline-block;
  transition: transform 0.25s ease-in-out;
  flex: 1;
  max-width: 44px;
`;

const SVG = styled.svg`
  display: inline-block;
  width: 100%;
  height: 100%;

  .stroke {
    stroke: var(--color, #000);
  }

  svg {
    width: 100%;
  }
`;

function IconAlert() {
  return (
    <IconContainer>
      <SVG
        width="40"
        height="40"
        viewBox="0 0 48 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 30.0686C23.5253 30.0686 23.0613 30.2036 22.6666 30.4565C22.2719 30.7095 21.9643 31.069 21.7827 31.4896C21.601 31.9102 21.5535 32.3731 21.6461 32.8196C21.7387 33.2661 21.9673 33.6763 22.3029 33.9982C22.6386 34.3201 23.0662 34.5394 23.5318 34.6282C23.9973 34.717 24.4799 34.6714 24.9184 34.4972C25.357 34.323 25.7318 34.0279 25.9955 33.6494C26.2592 33.2708 26.4 32.8258 26.4 32.3705C26.4 31.76 26.1471 31.1745 25.697 30.7428C25.2469 30.3111 24.6365 30.0686 24 30.0686Z"
          fill="#EE5253"
        />
        <path
          d="M24 25.8964V10.0708"
          stroke="#EE5253"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M24 44.5995C36.4264 44.5995 46.5 34.9376 46.5 23.0191C46.5 11.1006 36.4264 1.43872 24 1.43872C11.5736 1.43872 1.5 11.1006 1.5 23.0191C1.5 34.9376 11.5736 44.5995 24 44.5995Z"
          stroke="#EE5253"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </SVG>
    </IconContainer>
  );
}

export default IconAlert;

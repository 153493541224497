import axios, { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import { API_URL, INVENTORY_TYPE } from '@constants';
import {
  IBookingPayload,
  ISeatInfo,
  ICartItemDetails,
  IClientSecret,
  ICheckoutForm,
  IConfig,
  IProductInfo,
} from '../types';
import { dateToParamDMY, yyyyMMDD } from './functions';

const config = {
  headers: { 'Content-Type': 'application/json' },
};

const get = (endpoint: string) => axios.get(endpoint, config);

const post = (endpoint: string, data: any) => axios.post(endpoint, data, config);

const remove = (endpoint: string) => axios.delete(endpoint, config);

const preparePayload = (
  cartId,
  clientSecret,
  bookingPayload,
  billingFormData,
  items,
  bookedDate,
  micrositeConfig,
) => ({
  email: bookingPayload.user_details.email,
  inventory_type: INVENTORY_TYPE,
  enable_gdpr: false,
  user_details: {
    first_name: bookingPayload.user_details.first_name,
    last_name: bookingPayload.user_details.last_name,
    email: bookingPayload.user_details.email,
  },
  ...(billingFormData
    ? {
        cart_id: cartId,
        billing_details: {
          first_name: billingFormData.billingFirstName
            ? billingFormData.billingFirstName
            : bookingPayload.user_details.first_name,
          last_name: billingFormData.billingLastName
            ? billingFormData.billingLastName
            : bookingPayload.user_details.last_name,
          zip: billingFormData.billingZipCode ? billingFormData.billingZipCode : '12345',
          country: 'United States',
          phone: billingFormData.phone,
        },
      }
    : {
        billing_details: {
          first_name: bookingPayload.user_details.first_name,
          last_name: bookingPayload.user_details.last_name,
        },
      }),
  items: items.map((item) => ({
    ...item,
    ...(item.addon_items
      ? {
          addons: item.addon_items,
        }
      : {}),
    ...(Array.isArray(item.seats) && item.seats.length
      ? {
          seats: item.seats,
        }
      : {}),
    booked_date: bookedDate,
  })),
  metadata: {
    ...(clientSecret ? { payment_id: clientSecret.payment_id } : {}),
    agent: `hotel_${micrositeConfig.hotelID}.microsite`,
    currency_code: 'USD',
    country_code: 'US',
    note: bookingPayload.metadata.note,
  },
});

const createBooking = async (
  cartId: string,
  clientSecret: IClientSecret | undefined,
  bookingPayload: IBookingPayload,
  billingFormData: ICheckoutForm | undefined,
  items: any,
  bookedDate: string,
  micrositeConfig: IConfig,
) => {
  const payload: any = preparePayload(
    cartId,
    clientSecret,
    bookingPayload,
    billingFormData,
    items,
    bookedDate,
    micrositeConfig,
  );
  const { data } = await post(`${API_URL}/api/bookings`, payload);
  return data;
};

const updateBooking = async (
  bookingId: number,
  cartId: string,
  clientSecret: IClientSecret | undefined,
  bookingPayload: IBookingPayload,
  billingFormData: ICheckoutForm | undefined,
  items: any,
  bookedDate: string,
  micrositeConfig: IConfig,
) => {
  const payload: any = preparePayload(
    cartId,
    clientSecret,
    bookingPayload,
    billingFormData,
    items,
    bookedDate,
    micrositeConfig,
  );
  const { data } = await post(`${API_URL}/api/bookings/${bookingId}/update`, payload);
  return data;
};

const getSeats = async (params: {
  date: string;
  productID: any;
  hotelTimezone: string;
}): Promise<ISeatInfo[]> => {
  const response = await get(
    `${API_URL}/api/products/${params.productID}/seats?date=${dateToParamDMY(
      new Date(params.date),
    )}`,
  );
  const stringDate = moment(params.date, 'MM/DD/YYYY').format('YYYY-MM-DDT00:00:00');
  const nextDay = new Date(stringDate);
  nextDay.setDate(new Date(stringDate).getDate() + 1);
  const tStart = yyyyMMDD(new Date(stringDate));
  const tEnd = yyyyMMDD(nextDay);

  const data = response ? response.data.data : [];

  return data
    .filter((s) => s.enabled)
    .map((s) => ({
      ...s,
      t_start: tStart,
      t_end: tEnd,
    }))
    .sort((a, b) =>
      a.label.localeCompare(b.label, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );
};

const mergeImages = (product: any): string => {
  const defaultImage =
    product.photo_url && product.photo_url.one_x ? product.photo_url.one_x.url : '';
  const additionalImages =
    product.product_images && product.product_images.length
      ? product.product_images.map(
          (pi: { picture: { one_x: { url: any } } }) => pi.picture.one_x.url,
        )
      : [];
  return [defaultImage].concat(additionalImages).join();
};

const mapProducts = async (p: any, date, hotelTimezone) =>
  ({
    id: p.product_id || null,
    available: p.availability !== 'unavailable',
    addons: p.addons
      ? p.addons
          .filter((a) => a.active)
          .map((a: any) => ({
            addonType: a.addon_type || null,
            id: a.id,
            name: a.name,
            max: a.addon_type === 'single' ? 1 : a.max,
            price: parseFloat(a.price),
            houseDescription: a.house_description,
          }))
          .sort((a: any, b: any) => a.price - b.price)
      : [],
    name: p.name,
    desc: p.house_description || p.name,
    price: p.adult_price || 0,
    childPrice: p.children_price || 0,
    individualBilling: p.individual_billing || null,
    img: mergeImages(p),
    adultsAvailable: p.adult_available_unit || 0,
    childrenAvailable: p.child_available_unit || 0,
    maxAdults: p.max_adults_occupancy || 0,
    maxChildrenPerAdult: p.allow_child ? p.max_children_per_adult || 0 : 0,
    maxChildren: p.allow_child ? p.max_children_occupancy || 0 : 0,
    maxOccupancy: p.max_person_occupancy || 0,
    allowChildren: p.allow_child || false,
    reservedSeating: p.microsite_reserved_seating || false,
    sortOrder: p.sort_order || 0,
    house_require_payment: !!p.house_require_payment,
    seats: p.microsite_reserved_seating
      ? await getSeats({ productID: p.product_id, date, hotelTimezone })
      : [],
    timeSlotted: !!p.is_product_time_group,
    startTime: p.start_time || null,
    endTime: p.end_time || null,
    children: p.children
      ? await Promise.all(p.children.map((child) => mapProducts(child, date, hotelTimezone)))
      : [],
  } as IProductInfo);

const checkout = async (
  payload: IBookingPayload,
  successURL: string | undefined,
  cancelURL: string | undefined,
): Promise<{
  checkoutID: string;
}> => {
  const endpoint = `${API_URL}/api/carts/checkout`;
  const checkoutPayload = {
    ...payload,
    success_url: successURL,
    cancel_url: cancelURL,
  };

  return post(endpoint, checkoutPayload).then(({ data: { checkoutID } }) => ({
    checkoutID,
  }));
};

const createClientSecret = async (
  cartId: string,
  bookingPayload: IBookingPayload,
  currencyCode: string | null,
  billingFormData: ICheckoutForm,
  micrositeConfig: IConfig,
) => {
  const data: any = {
    cart_id: cartId,
    inventory_type: INVENTORY_TYPE,
    enable_gdpr: bookingPayload.enable_gdpr,
    billing_details: {
      first_name: billingFormData.billingFirstName,
      last_name: billingFormData.billingLastName,
      country: 'United States',
      zip: billingFormData.billingZipCode ? billingFormData.billingZipCode : '12345',
      phone: billingFormData.phone,
    },
    user_details: {
      first_name: bookingPayload.user_details.first_name,
      last_name: bookingPayload.user_details.last_name,
      email: bookingPayload.user_details.email,
    },
    metadata: {
      agent: `hotel_${micrositeConfig.hotelID}.microsite`,
      currency_code: 'USD',
      country_code: 'US',
    },
  };

  const { data: clientSecret } = await post(`${API_URL}/api/payments/for_booking`, data);
  return clientSecret;
};

const createCartAndAddItems = async (data: any): Promise<AxiosResponse<ICartItemDetails>> =>
  post(`${API_URL}/api/carts`, data);

const updateCart = async (cartId: string, data: any): Promise<AxiosResponse<ICartItemDetails>> =>
  post(`${API_URL}/api/carts/${cartId}/update`, data);

const getCartItems = async (cartId: string): Promise<ICartItemDetails> => {
  const response = await get(`${API_URL}/api/carts/${cartId}/checkout_info`);
  return response?.data ? response.data : {};
};

const removeCartItem = async (cartId: string, itemId: string): Promise<ICartItemDetails> => {
  const { data: cartData } = await remove(`${API_URL}/api/carts/${cartId}/items/${itemId}`);
  return cartData;
};

const deleteCart = (cartId: string): Promise<any> => remove(`${API_URL}/api/carts/${cartId}`);

const deleteAddOn = async (cartId: string, addOnId: number): Promise<ICartItemDetails> => {
  const { data: cartData } = await remove(`${API_URL}/api/carts/${cartId}/addon_items/${addOnId}`);
  return cartData;
};

export {
  getSeats,
  mergeImages,
  createBooking,
  updateBooking,
  checkout,
  createClientSecret,
  createCartAndAddItems,
  updateCart,
  getCartItems,
  removeCartItem,
  deleteCart,
  deleteAddOn,
  mapProducts,
};

import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const IconContainer = styled.span`
  display: inline-block;
  flex: 0 0 1em;
  margin-left: 1em;
  transition: transform 0.25s ease-in-out;
  height: 1em;
`;

const SVG = styled.svg`
  display: inline-block;
  width: 100%;
  height: 100%;

  .stroke {
    stroke: var(--color, #000);
  }

  svg {
    width: 100%;
  }
  &.up,
  &.down,
  &.left,
  &.right {
    transform-origin: 50% 50%;
  }

  &.up {
    transform: rotate(-90deg);
  }
  &.down {
    transform: rotate(90deg);
  }
  &.left {
    transform: rotate(180deg);
  }
`;

interface IIconArrowProps extends ComponentProps<any> {
  direction: 'up' | 'down' | 'left' | 'right';
}

function IconArrow({ className, direction }: IIconArrowProps) {
  return (
    <IconContainer>
      <SVG
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 1.5,
        }}
        className={classnames([className, direction])}
      >
        <g transform="matrix(1,0,0,1,-416,-364)">
          <path
            d="M422.9,366.089L433.188,375.905L423.408,385.685"
            style={{
              fill: 'none',
              strokeWidth: '3px',
            }}
            className="stroke"
          />
        </g>
      </SVG>
    </IconContainer>
  );
}

export default IconArrow;

import {
  CART_ID,
  CART_SEATS,
  PRODUCTS_SEATINGS,
  PAYMENT_REQUIRED,
  PREV_ROUTE,
  TIMESLOTTED_PRODUCTS,
} from '@constants';

type UseStorageReturnValue = {
  getItem: (key: string) => string;
  setItem: (key: string, value: string) => boolean;
  removeItem: (key: string) => void;
};

const localStorageKeys = [
  CART_ID,
  CART_SEATS,
  PRODUCTS_SEATINGS,
  PAYMENT_REQUIRED,
  PREV_ROUTE,
  TIMESLOTTED_PRODUCTS,
];

const useSessionStorage = (): UseStorageReturnValue => {
  const storageType = 'sessionStorage';
  const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')();

  const getItem = (key: string): string => {
    if (isBrowser) {
      if (localStorageKeys.indexOf(key) > -1) {
        return window.localStorage[key];
      }
      return window[storageType][key];
    }
    return '';
  };

  const setItem = (key: string, value: string): boolean => {
    if (isBrowser) {
      if (localStorageKeys.indexOf(key) > -1) {
        window.localStorage.setItem(key, value);
        return true;
      }
      window[storageType].setItem(key, value);
      return true;
    }
    return false;
  };

  const removeItem = (key: string): void => {
    if (localStorageKeys.indexOf(key) > -1) {
      window.localStorage.removeItem(key);
      return;
    }
    window[storageType].removeItem(key);
  };

  return {
    getItem,
    setItem,
    removeItem,
  };
};

export default useSessionStorage;

import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  display: inline-block;
  svg {
    width: 100%;
  }
  .fill {
    fill: var(--color, #b6b6b6);
  }

  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
`;

interface IIConCalenderProps {
  className?: string;
}

function IconGuest({ className }: IIConCalenderProps) {
  return (
    <SVG className={className} viewBox="0 0 33 37" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1,0,0,1,-177,-308)">
        <path
          d="M189.798,330.371C185.26,328.848 181.987,324.558 181.987,319.51C181.987,313.188 187.119,308.056 193.44,308.056C199.762,308.056 204.894,313.188 204.894,319.51C204.894,324.514 201.678,328.773 197.203,330.33C204.19,331.777 209.402,337.335 209.402,343.962C209.402,344 209.401,344.036 209.399,344.073L177.794,344.073C177.792,344.036 177.791,344 177.791,343.962C177.791,337.396 182.907,331.879 189.798,330.371ZM187.291,316.836C187.291,316.836 186.147,325.312 193.44,325.465C199.211,325.585 199.85,319.51 199.85,319.51C199.85,319.51 196.728,320.015 193.44,319.51C189.459,318.897 187.291,316.836 187.291,316.836Z"
          className="fill"
        />
      </g>
    </SVG>
  );
}
IconGuest.defaultProps = { className: undefined };

export default IconGuest;

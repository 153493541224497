export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT;
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const SHOP_API_URL = process.env.NEXT_PUBLIC_SHOP_API_URL;
export const REDIRECT_DOMAIN = process.env.NEXT_PUBLIC_REDIRECT_DOMAIN;
export const REDIRECT_404_URL = process.env.NEXT_PUBLIC_404_URL;
export const GTM_TAG = process.env.NEXT_PUBLIC_GTM_TAG;
export const PUBLIC_S3_STATIC_ASSETS_LINK = process.env.NEXT_PUBLIC_S3_STATIC_ASSETS_LINK;

export const INVENTORY_TYPE = 'house';

// Storage constants
export const CART_ID = 'CART_ID';
export const CART_SEATS = 'CART_SEATS';
export const PRODUCTS_SEATINGS = 'PRODUCTS_SEATINGS';
export const PAYMENT_REQUIRED = 'PAYMENT_REQUIRED';
export const PREV_ROUTE = 'PREV_ROUTE';
export const TIMESLOTTED_PRODUCTS = 'TIMESLOTTED_PRODUCTS';

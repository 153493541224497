import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { buildQueryParams } from '@utils';
import { useAppContext } from './Context';
import Crumbs from './Crumbs';
import { IConfig, IMicrositeImages, IProductCategory } from '../../types';
import Cart from './Cart';
import Logo from './Logo';

interface IHeaderProps {
  config: IConfig;
  categories: IProductCategory[];
  images?: IMicrositeImages;
}

function Header({ config, categories, images }: IHeaderProps) {
  const router = useRouter();
  const {
    state: { cart },
  } = useAppContext();

  const cartCount = cart.items.reduce((currentCount, item) => {
    let count = currentCount;
    if (item.individual) {
      count += item.no_of_adults + item.no_of_childs;
    } else {
      count += 1;
    }
    if (item.addon_items && item.addon_items.length) {
      item.addon_items
        .filter((addon) => addon)
        .forEach((addOn) => {
          count += addOn.units;
        });
    }
    return count;
  }, 0);

  return (
    <div id="HeaderContainer">
      <div id="logo-wrapper">
        <Logo config={config} images={images} />
      </div>
      {router.asPath.startsWith('/availability') ? (
        <div id="content">
          <div id="main-content">
            {categories ? (
              <Crumbs>
                <a className="active">Date &amp; Reservation</a>
                <span className="divider">&gt;</span>
                <a>Guest Information</a>
              </Crumbs>
            ) : null}
          </div>
          {cartCount > 0 && <Cart count={cartCount} />}
        </div>
      ) : null}
      {router.asPath.startsWith('/confirm') ? (
        <div id="content">
          <div id="main-content">
            <Crumbs>
              <Link href={`/availability?${buildQueryParams(router.query, {})}`}>
                Date &amp; Reservation
              </Link>
              <span className="divider">&gt;</span>
              <a className="active">Guest Information</a>
            </Crumbs>
          </div>
        </div>
      ) : null}
    </div>
  );
}
Header.defaultProps = { images: undefined };

export default Header;

import { GTM_TAG } from '@constants';
import Snippets from './snippets';

/**
 * Generate a simple tag and fill it with some content.
 * @param tagType the type of the tag to create. ex: 'noscript' | 'script' | 'style'
 * @param content the content of the tag.
 * @returns a new document element
 */
const scriptGenerator = (tagType: 'noscript' | 'script' | 'style', content: any) => {
  const element = document.createElement(tagType);
  element.innerHTML = content;

  return element;
};

const TagManager = {
  gtm(id: string) {
    const snippets = Snippets(id);

    const noScript = () => scriptGenerator('noscript', snippets.noScript);
    const mainScript = () => scriptGenerator('script', snippets.mainScript);

    return {
      mainScript,
      noScript,
    };
  },

  /**
   * Insert GTM script tags in its correspondent places.
   * @param gtmID the GTM ID to use, ex: GTM-XXXX
   */
  initialize(gtmID: string | undefined) {
    if (!gtmID) {
      return;
    }

    const canUseGTM = !!GTM_TAG;

    if (!canUseGTM) return;

    const gtm = this.gtm(gtmID);

    // Insert the tags
    // Using Optimize anti-flickering snippet directly in _document
    document.head.insertBefore(gtm.mainScript(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
};

export default TagManager;

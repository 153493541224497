import { NextPageContext } from 'next';
import { format, parse } from 'date-fns';
import axios from 'axios';
import xss from 'xss';
import { isNil, sumBy } from 'lodash';
import { REDIRECT_404_URL, REDIRECT_DOMAIN, SHOP_API_URL } from '@constants';
import moment, { Moment } from 'moment-timezone';
import { ICart, ICheckoutForm, IProductInfo } from '../types';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const pad = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  }
  return num.toString();
};

// utility for formatting date into query param
const dateToParam = (d: Date, timezone: string): string =>
  moment.tz(d, timezone).format('MM/DD/YYYY');

const dateToParamDMY = (d: any): string =>
  `${pad(d.getDate())}/${pad(d.getMonth() + 1)}/${d.getFullYear()}`;

const getPaddedNumber = (n: number) => n.toString().padStart(2, '0');

// eslint-disable-next-line
const buildQueryParams = (params: Record<string, any>, newParams: Record<string, any> = {}) => {
  const query: string[] = [];
  const allParams = { ...params, ...newParams };

  if (isNil(allParams.preview)) {
    delete allParams.preview;
  }

  if (!allParams.date) {
    allParams.date = new Date();
  }

  const entries = Object.entries(allParams);
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of entries) {
    if (value && key !== 'site') {
      if (key === 'date') {
        const date = value ? new Date(value as string) : new Date();
        const dateString = `${getPaddedNumber(date.getMonth() + 1)}/${getPaddedNumber(
          date.getDate(),
        )}/${date.getFullYear()}`;
        query.push(`${key}=${dateString}`);
      } else {
        query.push(`${key}=${value}`);
      }
    }
  }
  return query.join('&');
};

const sanitize = (unsafeHtml: string) => xss(unsafeHtml);

const DATE_REGEX = /(\d{4})-(0?\d{1,2})-(0?\d{1,2})/;

const formatDateToString = (date: Date | string) => {
  if (typeof date === 'string') {
    const results = DATE_REGEX.exec(date);
    if (!results) return '';
    const [y, m, d] = results.slice(1).map((part) => parseInt(part, 10));
    const dateObj = new Date(y, m - 1, d);
    return `${days[dateObj.getDay()]}, ${months[m - 1]} ${pad(d)}`;
  }
  return `${days[date.getDay()]}, ${months[date.getMonth()]} ${pad(date.getDate())}`;
};

const yyyyMMDD = (d: Date): string =>
  `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}`;

const getServerSideHotelDetails = async (context: NextPageContext) => {
  let data;
  try {
    ({ data } = await axios.get(`${SHOP_API_URL}/api/microsites/${context.query.site}/details`));
  } catch (e) {
    return {
      notFound: true,
    };
  }
  const {
    url,
    hotel_images: image,
    images,
    config,
    deployed,
    preview_config: previewConfig,
    hotel_id: hotelId,
    hotel_name: hotelName,
    microsite_reserved_seating: micrositeReservedSeating,
    default_seating_map_url: defaultSeatingMapUrl,
    house_changes_and_conditions: houseChangesAndConditions,
    timezoneInfo: hotelTimezone,
    house_booking_window: houseBookingWindow,
  } = data;

  const configToUse = context.query.preview === 'true' ? previewConfig : config;

  configToUse.name = hotelName;

  if (!configToUse || (!deployed && context.query.preview !== 'true')) {
    return {
      notFound: true,
    };
  }

  return {
    url,
    metadata: {
      hotel_id: hotelId,
    },
    hotelTimezone,
    micrositeReservedSeating,
    name: configToUse.name,
    config: configToUse,
    images,
    hotelId,
    defaultSeatingMapUrl,
    detailImages: image || [],
    houseChangesAndConditions,
    bookingWindow: houseBookingWindow,
  };
};

const shouldRedirect = (details, context) => {
  if (!details || details.notFound) {
    return {
      redirect: {
        destination: REDIRECT_404_URL,
        permanent: false,
      },
    };
  }

  const [url] = context.req.headers.host.split('.');
  if (details.url !== url) {
    return {
      redirect: {
        destination: `https://${details.url}.${REDIRECT_DOMAIN}`,
      },
    };
  }

  return null;
};

const inputHasError = (errors, key) => errors && key in errors;

const isValidEmail = (email) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );

const numberOfAvailableUnits = (productInfo: IProductInfo, cartData: ICart | undefined) => {
  const { id, adultsAvailable } = productInfo;
  let count = adultsAvailable;
  if (cartData) {
    const cartItem = cartData.items.filter((item) => item.product_id === id || item.id === id);

    if (cartItem && cartItem.length > 0) {
      cartItem.forEach((item) => {
        if (item.individual) {
          count -= item.no_of_adults;
        } else {
          count -= 1;
        }
      });
    }
  }
  return count;
};

const getIndividualBilledProductInventory = (product: IProductInfo, cartData: ICart): number => {
  const productCartItems = cartData.items.filter(
    (item) => item.product_id === product.id || item.id === product.id,
  );
  const inventoryInCart = sumBy(productCartItems, (item) => item.no_of_adults);
  return product.adultsAvailable - inventoryInCart;
};

const individualBilledCalc = (product: IProductInfo, cartData: ICart): boolean => {
  const remainingInventory = getIndividualBilledProductInventory(product, cartData);
  return remainingInventory > 0 && product.available && product.adultsAvailable > 0;
};

const nonIndividualBilledCalc = (product: IProductInfo, cartData: ICart): boolean => {
  const productCartItems = cartData.items.filter(
    (item) => item.product_id === product.id || item.id === product.id,
  );
  const remainingInventory = product.adultsAvailable - productCartItems.length;

  return remainingInventory > 0 && product.available && product.adultsAvailable > 0;
};

const isProductAvailable = (product: IProductInfo, cartData: ICart): boolean => {
  if (product.children && product.children.length > 0) {
    const productAndChildren = [product, ...product.children].filter((p) => p.id);

    return productAndChildren.some((p) => isProductAvailable(p, cartData));
  }

  if (product.individualBilling) {
    return individualBilledCalc(product, cartData);
  }

  return nonIndividualBilledCalc(product, cartData);
};

export type FormErrors = {
  [key: string]: boolean;
};

const formatTimeSlotTime = (time: string | null) => {
  const time2 = parse(time!, 'HH:mm:ss+00', new Date());
  return format(time2, 'h:mmaaa');
};

const formatTimeSlotTimeLabel = (timeslot: IProductInfo) =>
  `${formatTimeSlotTime(timeslot.startTime)} - ${formatTimeSlotTime(timeslot.endTime)}`;

const humanTimeSlotTime = (date: Date) => format(date, 'MMM d, yyyy');

const constructBillingDetails = (bookingData: ICheckoutForm) => {
  const firstName = bookingData.first_name;
  const lastName = bookingData.last_name;

  const billingDetail: any = {
    name: [firstName, lastName].join(' '),
  };

  if (bookingData.email) {
    billingDetail.email = bookingData.email;
  }
  return billingDetail;
};

const constructNotes = (config, cart, formData, confirmation) => {
  const notes: Array<Array<any>> = [];
  if (config.home.confirmation.type && config.home.confirmation.type === 'date') {
    notes.push(['date', yyyyMMDD(cart.items[0]?.booked_date || new Date())]);
  } else {
    notes.push([config.home.confirmation.placeholder || 'confirmation', confirmation]);
  }
  notes.push(['Additional Info', formData.additionalInfo]);

  if (formData.location) {
    notes.push(['Location Request', formData.location]);
  }

  let notesString = notes
    .concat(
      Array.prototype.slice
        .call(document.querySelectorAll('.input-element.dynamic'))
        .map((field) => {
          const k = field.name;
          const capitalize =
            k.length > 1 ? k.charAt(0).toUpperCase() + k.slice(1) : k.toUpperCase();
          return [capitalize, field.value];
        }),
    )
    .reduce((note, kv) => `${note}|| ${kv[0]}: ${kv[1]} `, '');
  notesString += '||';
  return notesString;
};

const getPaymentError = (error: any) => {
  if (error && error.message) {
    return {
      title: error.code ?? 'Error',
      message: error.message,
    };
  }

  return {
    title: 'Error',
    message:
      'Unfortunately, your reservation could not be completed. Please contact concierge@resortpass.com for assistance.',
  };
};

const getLastAvailableDate = (date: Moment, bookingWindow: number): Moment => {
  const result = moment(date).add(bookingWindow, 'months');

  if (result.isAfter(date)) {
    result.subtract(1, 'day');
  }

  return result;
};

export {
  dateToParam,
  dateToParamDMY,
  sanitize,
  yyyyMMDD,
  getServerSideHotelDetails,
  inputHasError,
  isValidEmail,
  formatDateToString,
  buildQueryParams,
  numberOfAvailableUnits,
  formatTimeSlotTime,
  formatTimeSlotTimeLabel,
  humanTimeSlotTime,
  constructBillingDetails,
  constructNotes,
  shouldRedirect,
  getPaymentError,
  getLastAvailableDate,
  isProductAvailable,
  getIndividualBilledProductInventory,
};

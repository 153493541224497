import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { ENVIRONMENT, GTM_TAG } from '@constants';
import Layout from '@common/Layout';
import { AppContextProvider } from '@common/Context';
import TagManager from '@utils/gtm';
import '../styles/globals.scss';
import 'flatpickr/dist/themes/light.css';
import 'react-toastify/dist/ReactToastify.min.css';

interface IMyAppProps {
  Component: any;
  pageProps: any;
}

function MyApp({ Component, pageProps }: IMyAppProps) {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY) {
      setStripePromise(loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY));
    }

    if (ENVIRONMENT === 'production' || ENVIRONMENT === 'staging') {
      TagManager.initialize(GTM_TAG);
    }
  }, []);
  return (
    <Elements stripe={stripePromise}>
      <AppContextProvider>
        <Layout
          {...pageProps}
          config={pageProps.config}
          categories={pageProps.productCategories}
          images={pageProps.images}
        >
          <Component {...pageProps} />
          <ToastContainer
            position={toast.POSITION.BOTTOM_CENTER}
            autoClose={5000}
            hideProgressBar
            closeOnClick
            draggable={false}
            transition={Slide}
          />
        </Layout>
      </AppContextProvider>
    </Elements>
  );
}

export default MyApp;

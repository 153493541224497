import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  display: inline-flex;
  height: 40px;
  width: 40px;

  @media screen and (min-width: 768px) {
    height: 28px;
    width: 28px;
  }
`;
const Path1 = styled.path`
  color: #000000;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: medium;
  line-height: normal;
  font-family: sans-serif;
  font-variant-ligatures: normal;
  font-variant-position: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-alternates: normal;
  font-feature-settings: normal;
  text-indent: 0;
  text-align: start;
  text-decoration: none;
  text-decoration-style: solid;
  text-decoration-color: #000000;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  writing-mode: lr-tb;
  direction: ltr;
  text-orientation: mixed;
  dominant-baseline: auto;
  baseline-shift: baseline;
  text-anchor: start;
  white-space: normal;
  shape-padding: 0;
  clip-rule: nonzero;
  display: inline;
  overflow: visible;
  visibility: visible;
  opacity: 1;
  isolation: auto;
  mix-blend-mode: normal;
  color-interpolation: sRGB;
  color-interpolation-filters: linearRGB;
  solid-color: #000000;
  solid-opacity: 1;
  vector-effect: none;
  fill-opacity: 1;
  fill-rule: nonzero;
  stroke: none;
  stroke-width: 1;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-dashoffset: 0;
  stroke-opacity: 1;
  color-rendering: auto;
  image-rendering: auto;
  shape-rendering: auto;
  text-rendering: auto;
  enable-background: accumulate;
`;

const Path2 = styled.path`
  color: #000000;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: medium;
  line-height: normal;
  font-family: sans-serif;
  font-variant-ligatures: normal;
  font-variant-position: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-alternates: normal;
  font-feature-settings: normal;
  text-indent: 0;
  text-align: start;
  text-decoration: none;
  text-decoration-style: solid;
  text-decoration-color: #000000;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  writing-mode: lr-tb;
  direction: ltr;
  text-orientation: mixed;
  dominant-baseline: auto;
  baseline-shift: baseline;
  text-anchor: start;
  white-space: normal;
  shape-padding: 0;
  clip-rule: nonzero;
  display: inline;
  overflow: visible;
  visibility: visible;
  opacity: 1;
  isolation: auto;
  mix-blend-mode: normal;
  color-interpolation: sRGB;
  color-interpolation-filters: linearRGB;
  solid-color: #000000;
  solid-opacity: 1;
  vector-effect: none;
  fill-opacity: 1;
  fill-rule: nonzero;
  stroke: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: none;
  stroke-dashoffset: 0;
  stroke-opacity: 1;
  color-rendering: auto;
  image-rendering: auto;
  shape-rendering: auto;
  text-rendering: auto;
  enable-background: accumulate;
`;

function IconCart() {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" version="1.1">
      <g id="g5086" className="fill">
        <Path1
          d="m 22.252783,23.766999 c -1.37479,0 -2.5,1.12521 -2.5,2.5 0,1.37479 1.12521,2.5 2.5,2.5 1.37479,0 2.5,-1.12521 2.5,-2.5 0,-1.37479 -1.12521,-2.5 -2.5,-2.5 z"
          id="path4528"
          className="fill"
        />
        <Path1
          d="m 22.252783,24.766999 c 0.834349,0 1.5,0.665651 1.5,1.5 0,0.834349 -0.665651,1.5 -1.5,1.5 -0.834349,0 -1.5,-0.665651 -1.5,-1.5 0,-0.834349 0.665651,-1.5 1.5,-1.5 z"
          id="circle4485"
          className="fill"
        />
        <Path1
          d="m 12.252783,23.766999 c -1.374789,0 -2.4999987,1.12521 -2.4999987,2.5 0,1.37479 1.1252097,2.5 2.4999987,2.5 1.37479,0 2.5,-1.12521 2.5,-2.5 0,-1.37479 -1.12521,-2.5 -2.5,-2.5 z"
          id="path4524"
          className="fill"
        />
        <Path1
          d="m 12.252783,24.766999 c 0.834349,0 1.5,0.665651 1.5,1.5 0,0.834349 -0.665651,1.5 -1.5,1.5 -0.834349,0 -1.499999,-0.665651 -1.499999,-1.5 0,-0.834349 0.66565,-1.5 1.499999,-1.5 z"
          id="circle4487"
          className="fill"
        />
        <Path1
          d="M 6.5691903,9.7669996 6.7762213,10.419343 10.38755,21.766999 H 24.635595 L 27.90708,9.7669996 Z"
          id="path4520"
          className="fill"
        />
        <Path1
          d="M 7.9363773,10.767 H 26.598486 l -2.728515,9.999999 H 11.118018 Z"
          id="polygon4489"
          className="fill"
        />
        <Path2
          d="m 2.2527843,3.2669996 a 1.0001,1.0001 0 1 0 0,2 h 1.878906 c 0.225902,0 0.414404,0.141463 0.478516,0.359375 l 4.261718,14.4882804 c 0.374034,1.271714 1.5502687,2.152344 2.8769527,2.152344 h 10.976562 c 1.348416,0 2.539001,-0.911308 2.894532,-2.210938 l 2.597656,-9.52539 A 1.0001,1.0001 0 0 0 27.252783,9.2669996 H 7.7664563 l -1.236329,-4.205079 a 1.0001,1.0001 0 0 0 -0.0019,0 c -0.311891,-1.060088 -1.292389,-1.794921 -2.396487,-1.794921 z"
          id="path4516"
          className="fill"
        />
        <Path2
          d="M 8.3543463,11.267 H 25.944189 l -2.253906,8.261718 c -0.120469,0.44037 -0.509259,0.738281 -0.964844,0.738281 H 11.748877 c -0.447316,0 -0.833017,-0.28851 -0.958984,-0.716797 z"
          id="path4491"
          className="fill"
        />
      </g>
    </SVG>
  );
}

export default IconCart;

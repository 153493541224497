/**
 * Generate GTM script tags for header and body.
 * @param id the GTM id, ex: GTM-XXXX.
 * @returns noScript, mainScript
 */
const snippets = (id: string) => {
  const noScript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

  const mainScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');`;

  return {
    noScript,
    mainScript,
  };
};

export default snippets;

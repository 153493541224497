import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  box-sizing: border-box;
  background-color: var(--primary-color, #ad0025);
  border-radius: 8px;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 1.1em;
  text-align: center;
  outline: 0;
  padding: 1em;

  align-self: stretch;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 1 10em;
  margin-left: 1em;
  margin-right: 1em;
  &[disabled],
  &[disabled='true'] {
    background-color: #ccc;
    color: #efefef;
    pointer-events: none;
  }
`;

const GhostButton = styled(Button)`
  background: transparent;
  border: none;
  max-width: 36px;
  padding: 4px;
  margin: 0;
`;

interface IIConButtonProps {
  icon: React.ReactElement;
  onClick: () => void;
}

export function IconButton({ icon, onClick }: IIConButtonProps) {
  return <GhostButton onClick={onClick}>{icon}</GhostButton>;
}

export default Button;

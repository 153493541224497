import { getDate, getMonth, getYear } from 'date-fns';
import { INVENTORY_TYPE } from '@constants';
import { keyBy } from 'lodash';
import { createCartAndAddItems, deleteAddOn, removeCartItem, updateCart } from './http';

import { ICartItem, ICartItemDetails, ICartPayload, ICart, IProductInfo } from '../types';

const getDateWithDashes = (date: Date): string => {
  const day = getDate(date);
  const dayText = day < 10 ? `0${day}` : day;
  const monthNumber = getMonth(date);
  const monthText = monthNumber < 9 ? `0${monthNumber + 1}` : monthNumber + 1;
  const year = getYear(date);
  return `${year}-${monthText}-${dayText}`;
};
const createCartItems = async (data: ICartPayload) => createCartAndAddItems(data);

const removeSingleAddOn = async (cartId: ICartItemDetails['id'], addOnId: number) =>
  deleteAddOn(cartId, addOnId);

const createOrUpdateCartItems = async (
  data: ICartPayload,
  cartId: ICartItemDetails['id'] | undefined,
) => {
  if (cartId) {
    return updateCart(cartId, data);
  }
  return createCartItems(data);
};

export const prepareCartPayload = (
  items: ICartItem[] | undefined,
  cartDate: Date | string | null,
) => {
  const cartItems = [...(items || [])];
  const cartPayload: ICartPayload = {
    items: [],
    inventory_type: INVENTORY_TYPE,
  };
  if (cartItems) {
    cartPayload.items = cartItems.map((cartItem) => ({
      product_id: cartItem.product_id,
      hotel_id: cartItem.hotel_id,
      booked_date: getDateWithDashes(new Date(cartDate || new Date())),
      no_of_adults: cartItem.no_of_adults,
      no_of_childs: cartItem.no_of_childs,
      no_of_infants: 0,
      id: cartItem.product_id !== cartItem.id ? cartItem.id : undefined,
      addons: cartItem.addon_items,
      seats: cartItem.seats,
    }));
  }
  return cartPayload;
};

const removeSingleCartItem = async (cartId: string, item: ICartItem) =>
  removeCartItem(cartId, item.id?.toString());

const isHousePaymentRequired = (cart: ICart, products: IProductInfo[]) => {
  const productsAndChildren = products
    .flatMap((p) => (p.children ? [p, ...p.children] : [p]))
    .filter((p) => p.id);
  const productsByKey = keyBy(productsAndChildren, 'id');
  return cart.items.some((ci) => productsByKey[ci.product_id]?.house_require_payment);
};

export {
  createCartItems,
  createOrUpdateCartItems,
  removeSingleCartItem,
  removeSingleAddOn,
  isHousePaymentRequired,
};

import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  display: inline-flex;
  height: 40px;
  width: 40px;

  @media screen and (min-width: 768px) {
    height: 28px;
    width: 28px;
  }
`;

function IconPalmTree() {
  return (
    <SVG width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.625 8.75001L17.0333 13.2848C17.1604 13.3626 17.3056 13.4055 17.4545 13.4095C17.6034 13.4134 17.7508 13.3782 17.8818 13.3073C18.0128 13.2364 18.1229 13.1324 18.201 13.0056C18.2792 12.8788 18.3227 12.7337 18.3272 12.5848C18.4314 11.6259 18.2433 10.6579 17.7875 9.80782C17.3318 8.95772 16.6297 8.26527 15.7733 7.82134C15.035 7.45296 14.237 7.21908 13.4167 7.13068"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62502 8.74998H1.75002C1.6095 8.74304 1.47258 8.70315 1.35032 8.63354C1.22806 8.56392 1.12389 8.46652 1.04622 8.34922C0.968544 8.23191 0.919547 8.09798 0.903184 7.95825C0.88682 7.81851 0.90355 7.67689 0.95202 7.54481C1.30755 6.61282 1.94129 5.81282 2.7672 5.25345C3.5931 4.69409 4.57114 4.40245 5.56852 4.41814C6.64648 4.4477 7.69998 4.74607 8.63335 5.28614"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0081 7.50048L18.8686 5.20215C18.9756 5.14041 19.0661 5.05354 19.1321 4.94904C19.1981 4.84454 19.2377 4.72554 19.2475 4.60232C19.2572 4.4791 19.2369 4.35536 19.1881 4.24177C19.1394 4.12817 19.0637 4.02815 18.9677 3.95032C18.2642 3.35318 17.4084 2.9635 16.4961 2.82484C15.5838 2.68618 14.6509 2.80401 13.8017 3.16515C13.0393 3.50736 12.4117 4.09235 12.0167 4.82882"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.89147 4.59549L5.4973 2.00666C5.43278 1.88803 5.4008 1.75444 5.4046 1.61946C5.4084 1.48447 5.44785 1.35289 5.51894 1.23808C5.59003 1.12327 5.69023 1.02932 5.80937 0.96575C5.92851 0.902184 6.06235 0.871274 6.1973 0.876157C7.05903 0.92762 7.91383 1.06162 8.74997 1.27632C9.54294 1.55883 10.2554 2.02976 10.8261 2.64859C11.3967 3.26741 11.8085 4.01561 12.026 4.82882"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.125 23.625H16.7802C16.4679 23.6251 16.1612 23.5416 15.8921 23.3833C15.6229 23.2249 15.4011 22.9974 15.2495 22.7243L12.25 16.625"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6273 23.625L24.5023 27.125"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8783 23.373L14.0023 27.125"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5415 19.25H19.25"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.875 27.125C1.0115 24.801 0.98 14.966 8.75 8.75"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62502 8.75C7.20222 14.5523 6.29877 20.8764 7.00002 27.125"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
}

export default IconPalmTree;

import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  display: inline-block;
  svg {
    width: 100%;
  }
  .color-fill {
    fill: var(--color, #b6b6b6);
  }

  .color-stroke {
    stroke: var(--color, #b6b6b6);
  }
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
`;

interface IIConCalenderProps {
  className?: string;
}

function IconCalendar({ className }: IIConCalenderProps) {
  return (
    <SVG className={className} viewBox="0 0 44 42" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1,0,0,1,-378,-280)">
        <g>
          <g transform="matrix(0.904184,0,0,0.881604,38.2393,35.8079)">
            <rect
              className="color-stroke"
              x="378.32"
              y="283.897"
              width="43.552"
              height="38.207"
              style={{ fill: 'none', strokeWidth: '5.04px' }}
            />
          </g>
          <g transform="matrix(1.03414,0,0,1.19474,9.0406,-47.2291)">
            <rect className="color-fill" x="385.805" y="273.958" width="3.838" height="9.939" />
          </g>
          <g transform="matrix(1.03414,0,0,1.19474,-10.9594,-47.2291)">
            <rect className="color-fill" x="385.805" y="273.958" width="3.838" height="9.939" />
          </g>
          <g transform="matrix(1,0,0,1,10.0317,0)">
            <rect className="color-fill" x="385.986" y="296.138" width="7.964" height="7.975" />
          </g>
          <g transform="matrix(1,0,0,1,20.0317,0)">
            <rect className="color-fill" x="385.986" y="296.138" width="7.964" height="7.975" />
          </g>
          <g transform="matrix(1,0,0,1,10.0317,10)">
            <rect className="color-fill" x="385.986" y="296.138" width="7.964" height="7.975" />
          </g>
          <g transform="matrix(1,0,0,1,0.0316712,10)">
            <rect className="color-fill" x="385.986" y="296.138" width="7.964" height="7.975" />
          </g>
          <g transform="matrix(1,0,0,1,0.0316712,0)">
            <rect className="color-fill" x="385.986" y="296.138" width="7.964" height="7.975" />
          </g>
        </g>
      </g>
    </SVG>
  );
}
IconCalendar.defaultProps = { className: undefined };

export default IconCalendar;

import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  display: inline-flex;
  height: 40px;
  width: 40px;

  @media screen and (min-width: 768px) {
    height: 28px;
    width: 28px;
  }
`;

function IconCalendarFlat() {
  return (
    <SVG width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.3773 4.375H2.62732C1.66082 4.375 0.877319 5.1585 0.877319 6.125V25.375C0.877319 26.3415 1.66082 27.125 2.62732 27.125H25.3773C26.3438 27.125 27.1273 26.3415 27.1273 25.375V6.125C27.1273 5.1585 26.3438 4.375 25.3773 4.375Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.877319 11.375H27.1273"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87732 7V0.875"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1273 7V0.875"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
}

export default IconCalendarFlat;

import React from 'react';
import styled from 'styled-components';
import NImage from 'next/image';

const LogoContainer = styled.span`
  display: inline-block;
  margin-right: 1em;
  margin-top: 0.3em;
  --color: var(--text-color, #777);
  width: 1.6em;
`;

function IconLogo() {
  return (
    <LogoContainer>
      <NImage priority src="/images/logo.svg" width={18} height={14} alt="ResortPass Logo" />
    </LogoContainer>
  );
}

export default IconLogo;

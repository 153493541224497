import React from 'react';
import styled from 'styled-components';
import NImage from 'next/image';
import { IMicrositeImages } from '../../types';

const LoaderContainer = styled.div`
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  #logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(10vh * var(--scale, 1.8));
    font-size: calc(0.8em * var(--scale, 1.8));
    transform: scale(1 / var(--scale, 1.8));
    img {
      width: 100%;
    }
  }

  #dots {
    margin-top: 6em;
    display: flex;
    justify-content: center;
    position: relative;
  }

  #dots div {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: var(--primary-color, #ad0025);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    margin: 0 0.3em;
  }

  #dots div:first-child {
    left: 0;
    position: absolute;
    animation: scale 0.6s infinite reverse;
  }

  #dots div:nth-child(2) {
    animation: 0.6s slide infinite;
  }
  #dots div:nth-child(3) {
    animation: 0.6s slide infinite;
  }
  #dots div:nth-child(4) {
    animation: 0.6s scale infinite;
  }

  @keyframes slide {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(1.6em, 0, 0);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0, 0);
    }
  }
`;

interface ILoaderProps {
  images?: IMicrositeImages;
}

function Loader({ images }: ILoaderProps) {
  return (
    <LoaderContainer>
      <div id="logo" style={{ position: 'relative', height: 165, width: 165 }}>
        <NImage
          id="logo-img"
          alt="loading"
          layout="fill"
          objectFit="contain"
          src={images?.logo || 'https://dummyimage.com/400x360/ccc/ffffff&text=No+image+available'}
          loading="eager"
        />
        <div id="dots">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </LoaderContainer>
  );
}
Loader.defaultProps = { images: undefined };

export default Loader;

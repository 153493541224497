import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { flatten } from 'lodash';

import {
  checkout,
  createBooking,
  getCartItems,
  yyyyMMDD,
  createOrUpdateCartItems,
  prepareCartPayload,
  removeSingleAddOn,
  removeSingleCartItem,
  updateBooking,
  deleteCart,
} from '@utils';
import {
  CART_ID,
  CART_SEATS,
  PRODUCTS_SEATINGS,
  PAYMENT_REQUIRED,
  PREV_ROUTE,
  TIMESLOTTED_PRODUCTS,
} from '@constants';
import type {
  IBookingPayload,
  ICart,
  ICartItemDetails,
  IClientSecret,
  IConfig,
  ICartItem,
  IProductInfo,
  ISeatInfo,
  IAddonItems,
  ICheckoutForm,
} from '../../types';

import useSessionStorage from '../useSessionStorage';

interface IParams {
  category: string;
  date: string;
  confirmation: string;
}

interface IAppContext {
  state: {
    isLoading: boolean;
    isUpdatingCart: boolean;
    isOverlayOpen: boolean;
    isCartOpen: boolean;
    date: Date | null;
    category: string | null;
    confirmation: string | null;
    requirePayment: boolean;
    isSeatingMapModalOpen: boolean;
    seatsOnSVG: any[];
    errorPopup: {
      text?: string;
      open: boolean;
      callback?: () => void;
    };
    cartId: ICartItemDetails['id'] | null;
    cart: ICart;
    params: Partial<IParams>;
  };
  actions: {
    clearState: (isLoading?: boolean) => void;
    deleteCart: () => Promise<void>;
    clearCartId: () => void;
    clearCartStorage: () => void;
    toggleOverlay: () => void;
    toggleCart: (forceFlag?: boolean) => void;
    setIsLoading: (loading: boolean) => void;
    setDate: (date: Date | null) => void;
    setCategory: (category: string | null) => void;
    setConfirmation: (confirmation: string | null) => void;
    setPaymentRequired: (required: boolean) => void;
    getPaymentRequired: () => boolean;
    setCartSeats: (cartItems: any[]) => void;
    removeCartSeat: (product: IProductInfo, seatLabel: string) => void;
    addTimeSlottedCart: (productId: IProductInfo['id']) => void;
    removeTimeSlottedCart: (productId: IProductInfo['id']) => void;
    isTimeSlottedInCart: (productId: IProductInfo['id']) => boolean;
    clearTimeSlottedInCart: () => void;
    getCartSeats: () => Record<any, Array<{ id: number; seats: ISeatInfo[] }>>;
    getAllProductSeatsLabels: (product: IProductInfo) => string[];
    setProductSeatings: (products: IProductInfo[]) => void;
    getProductSeatings: () => Record<any, boolean>;
    setPreviousRoute: (router: string) => void;
    getPreviousRoute: () => string;
    toggleSeatingMapModal: () => void;
    addToCart: (cartItem: ICartItem, removeChildren?: boolean) => Promise<void>;
    removeFromCart: (cartItem: ICartItem, products: IProductInfo[]) => void;
    removeChildrenFromCart: (cartItem: ICartItem) => void;
    removeAddonFromCart: (cartItem: ICartItem, addon: IAddonItems) => void;
    calculateTotal: (cart: ICart) => Promise<void>;
    setErrorPopup: (errors: {
      title?: string;
      text?: string;
      open: boolean;
      callback?: () => void;
    }) => void;
    booking: (
      bookingPayload: IBookingPayload,
      clientSecret: IClientSecret | undefined,
      billingFormData: ICheckoutForm | undefined,
      config: IConfig,
      bookingId?: number | undefined,
    ) => Promise<{ id: number } | undefined>;
    checkout: (
      bookingPayload: IBookingPayload,
      successURL?: string,
      cancelURL?: string,
    ) =>
      | Promise<{
          checkoutID: string;
        }>
      | any;
    setSeatsOnSVG: (seat: any) => void;
    cleanSeatsOnSVG: () => void;
  };
}

export const AppContext = createContext<IAppContext | undefined>(undefined);

const INITIAL_STATE: IAppContext['state'] = {
  cartId: null,
  isLoading: true,
  isUpdatingCart: false,
  isOverlayOpen: false,
  isCartOpen: false,
  date: new Date(),
  category: null,
  confirmation: null,
  requirePayment: false,
  isSeatingMapModalOpen: false,
  seatsOnSVG: [],
  errorPopup: {
    open: false,
    text: '',
    callback: undefined,
  },
  cart: {
    items: [],
  } as unknown as ICart,
  params: {},
};

export function AppContextProvider({
  children,
  initialState = {},
}: PropsWithChildren & { initialState?: any }) {
  const [state, setState] = useState<IAppContext['state']>(() => ({
    ...INITIAL_STATE,
    ...initialState,
  }));

  const router = useRouter();
  const { getItem, setItem, removeItem } = useSessionStorage();

  const actions: IAppContext['actions'] = useMemo<IAppContext['actions']>(
    () => ({
      clearState: (isLoading?: boolean) => {
        setState({
          ...INITIAL_STATE,
          cartId: null,
          cart: {
            ...INITIAL_STATE.cart,
            items: [],
          },
          isLoading: isLoading ?? INITIAL_STATE.isLoading,
        });
      },
      deleteCart: () => deleteCart(state.cartId!),
      clearCartId: () => {
        removeItem(CART_ID);
      },
      clearCartStorage: () => {
        removeItem(CART_ID);
        removeItem(CART_SEATS);
        removeItem(PRODUCTS_SEATINGS);
        actions.clearTimeSlottedInCart();
      },
      toggleCart: (forceFlag) => {
        setState((oldState) => {
          let isCartOpen;
          if (typeof forceFlag === 'boolean') {
            isCartOpen = forceFlag;
          } else {
            isCartOpen = !oldState.isCartOpen;
          }
          return {
            ...oldState,
            isCartOpen,
            isOverlayOpen: isCartOpen,
          };
        });
      },
      toggleOverlay: () => {
        setState((oldState) => ({
          ...oldState,
          isOverlayOpen: !oldState.isOverlayOpen,
        }));
      },
      setIsLoading: (isLoading) => {
        setState((oldState) => ({ ...oldState, isLoading }));
      },
      setDate(date: Date | null): void {
        setState((oldState) => ({ ...oldState, date }));
      },
      setCategory(category: string | null): void {
        setState((oldState) => ({ ...oldState, category }));
      },
      setConfirmation(confirmation: string | null): void {
        setState((oldState) => ({ ...oldState, confirmation }));
      },
      setPaymentRequired(requirePayment: boolean): void {
        setItem(PAYMENT_REQUIRED, JSON.stringify(requirePayment));
      },
      getPaymentRequired(): boolean {
        const requirePayment = getItem(PAYMENT_REQUIRED);
        try {
          return JSON.parse(requirePayment);
        } catch (e) {
          return false;
        }
      },
      toggleSeatingMapModal(): void {
        setState((oldState) => ({
          ...oldState,
          isSeatingMapModalOpen: !oldState.isSeatingMapModalOpen,
        }));
      },
      setErrorPopup(error): void {
        setState((oldState) => ({
          ...oldState,
          errorPopup: {
            open: error.open,
            title: error.title ?? 'Error',
            text:
              error.text ??
              'Unfortunately, your reservation could not be completed. Please contact concierge@resortpass.com for assistance',
            callback: error.callback,
          },
        }));
      },
      addToCart: async (_cartItem, removeChildren = false) => {
        const { cart: cartOriginal, date } = state;
        const cart = { ...cartOriginal, items: [...cartOriginal.items] };
        const items = [...cart.items];
        const cartItem = { ..._cartItem };
        setState((oldState) => ({
          ...oldState,
          isUpdatingCart: true,
        }));

        cartItem.units = _cartItem.no_of_adults || 0;
        const itemsPayload: ICartItem[] = [];
        if (cartItem.individual) {
          const foundIndex = items.findIndex((i) => i.product_id === cartItem.product_id);
          const existing = items[foundIndex];
          if (existing) {
            if (removeChildren) {
              existing.no_of_childs = 0;
            } else {
              existing.units += cartItem.units;
              existing.price += cartItem.price;
              existing.no_of_adults += cartItem.no_of_adults;
              if (cartItem.no_of_childs) {
                existing.no_of_childs = (existing.no_of_childs || 0) + cartItem.no_of_childs;
              }
              existing.addon_items = existing.addon_items.concat(cartItem.addon_items);
            }
            itemsPayload.push(existing);
            items.push(existing);
          } else {
            itemsPayload.push(cartItem);
            items.push(cartItem);
          }
        } else {
          itemsPayload.push(cartItem);
          items.push(cartItem);
        }

        const payload = prepareCartPayload(itemsPayload, date);

        const retry = async () => {
          actions.setIsLoading(true);
          actions.clearCartStorage();
          await actions.addToCart(_cartItem, removeChildren);
          actions.setIsLoading(false);
        };

        const retryOrError = async (message: string) => {
          const m = message.toLowerCase();
          const isCartNotFoundError = m && m.includes('cart') && m.includes('was not found');
          if (isCartNotFoundError) {
            await retry();
          } else {
            actions.setErrorPopup({
              open: true,
              title: 'Error',
              text: message,
            });
          }
        };

        try {
          const response: any = await createOrUpdateCartItems(payload, getItem(CART_ID));

          const { data: cartDetails } = response;

          const newItems = cartDetails.items.map((item, index) => ({
            ...item,
            ...items[index],
            id: item.id,
            reservedSeating: item.enable_reserved_seating,
            addon_items: item.addon_items || [],
          }));

          actions.setCartSeats(newItems);

          setState((oldState) => ({
            ...oldState,
            cart: {
              ...cart,
              items: newItems,
              total: cartDetails.total,
              tax: cartDetails.tax,
            },
            isUpdatingCart: false,
            cartId: cartDetails.id,
          }));
        } catch (error: unknown | any) {
          let message = 'Something went wrong. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message;
          } else if (error.message) {
            message = error.message;
          }
          await retryOrError(message);
        } finally {
          setState((oldState) => ({ ...oldState, isUpdatingCart: false }));
        }
      },
      removeFromCart: (cartItem, products) => {
        const { cart: cartOriginal, cartId } = state;
        const cart = { ...cartOriginal, items: [...cartOriginal.items] };
        const items = [...cart.items];
        const removeIndex = items.findIndex((item) => item.id === cartItem.id);

        setState((oldState) => ({ ...oldState, isUpdatingCart: true, isLoading: true }));

        removeSingleCartItem(cartId!, items[removeIndex]).then((cartDetails) => {
          if (removeIndex > -1) {
            items.splice(removeIndex, 1);
          }
          const productsAndChildren = products
            .flatMap((p) => (p.children ? [p, ...p.children] : [p]))
            .filter((p) => p.id);
          const seatLabel = cartItem.seats && cartItem.seats[0] && cartItem.seats[0].label;
          const product = productsAndChildren.find((p) => p.id === cartItem.product_id);

          if (seatLabel && product) {
            product.seats = product.seats.map((seat) => {
              const seatCopy = { ...seat };
              if (seatCopy.label === seatLabel) {
                seatCopy.status = 'unassigned';
              }
              return seatCopy;
            });
            actions.removeCartSeat(product, seatLabel);
          }

          actions.removeTimeSlottedCart(cartItem.product_id);

          if (cartDetails.items.length === 0) actions.clearCartStorage();

          setState((oldState) => ({
            ...oldState,
            cart: {
              ...cart,
              items: cartDetails.items.map((item, index) => ({
                ...item,
                ...items[index],
                id: item.id,
                reservedSeating: item.enable_reserved_seating,
              })),
              total: cartDetails.total,
              tax: cartDetails.tax,
            },
            isUpdatingCart: false,
            isLoading: false,
            cartId: cartDetails.items.length > 0 ? cartDetails.id : null,
          }));
        });
      },
      removeChildrenFromCart: (cartItem) => {
        actions.addToCart(cartItem, true);
      },
      removeAddonFromCart: (cartItem, addon) => {
        const { cart, cartId } = state;
        const itemIndex = cart.items.findIndex((item) => item.product_id === cartItem.product_id);
        const addonIndex = cart.items[itemIndex].addon_items.findIndex(
          (addonItem) => addonItem.id === addon.id,
        );
        if (addonIndex > -1) {
          cart.items[itemIndex].addon_items.splice(addonIndex, 1);
        }

        setState((oldState) => ({ ...oldState, isUpdatingCart: true, isLoading: true }));

        removeSingleAddOn(cartId!, addon.id).then((cartDetails) => {
          setState((oldState) => ({
            ...oldState,
            cart: {
              ...cart,
              items: [...cart.items],
              total: cartDetails.total,
              tax: cartDetails.tax,
            },
            isUpdatingCart: false,
            isLoading: false,
            cartId: cartDetails.id,
          }));

          setState((oldState) => ({ ...oldState, ...cart }));
        });
      },
      calculateTotal: async (cart: ICart) => {
        // early return if there are no cart items
        if (!cart.items.length) {
          setState((oldState) => ({
            ...oldState,
            cart: {
              total: null,
              tax: null,
              currency: null,
              items: [],
            },
            isLoading: false,
          }));
        }
      },
      booking: async (bookingPayload, clientSecret, billingFormData, config, bookingId) => {
        setState((oldState) => ({ ...oldState, isUpdatingCart: true, isLoading: true }));
        let booking;
        try {
          if (bookingId) {
            booking = await updateBooking(
              bookingId,
              state.cartId!,
              clientSecret,
              bookingPayload,
              billingFormData,
              state.cart.items,
              yyyyMMDD(state.date || new Date()),
              config,
            );
          } else {
            booking = await createBooking(
              state.cartId!,
              clientSecret,
              bookingPayload,
              billingFormData,
              state.cart.items,
              yyyyMMDD(state.date || new Date()),
              config,
            );
          }
        } catch (err) {
          if (
            err instanceof AxiosError &&
            err.response &&
            err.response.data &&
            err.response.data.event === 'inventory_unavailable'
          ) {
            throw new Error(err.response.data.message);
          } else {
            throw new Error('One or more products from your cart are no longer available.');
          }
        } finally {
          setState((oldState) => ({
            ...oldState,
            isUpdatingCart: false,
            isLoading: false,
          }));
        }
        return booking;
      },
      checkout: (bookingPayload: IBookingPayload, successURL?: string, cancelURL?: string) => {
        setState((oldState) => ({
          ...oldState,
          isLoading: true,
        }));
        return checkout(bookingPayload, successURL, cancelURL)
          .catch((err) => {
            setState((oldState) => ({
              ...oldState,
              isLoading: false,
              errorPopup: {
                open: true,
                text: err.message,
              },
            }));
          })
          .finally(() => {
            setState((oldState) => ({
              ...oldState,
              isLoading: false,
            }));
          });
      },
      setSeatsOnSVG: (seats: any) => {
        setState((oldState) => ({ ...oldState, seatsOnSVG: seats }));
      },
      cleanSeatsOnSVG: () => {
        setState((oldState) => ({ ...oldState, seatsOnSVG: [] }));
      },
      setCartSeats: (cartItems: any[]) => {
        const cartSeats = cartItems.reduce((current, cartItem) => {
          const currentCopy = { ...current };
          currentCopy[cartItem.product_id] = currentCopy[cartItem.product_id] || [];
          currentCopy[cartItem.product_id].push({
            id: cartItem.id,
            seats: cartItem.seats,
          });
          return currentCopy;
        }, {});
        setItem(CART_SEATS, JSON.stringify(cartSeats));
      },
      removeCartSeat: (product: IProductInfo, seatLabel: string) => {
        const allCartSeats = actions.getCartSeats();
        const cartSeats = allCartSeats[product.id];
        const foundSeat = cartSeats.findIndex(({ seats }) =>
          flatten(seats).find((s) => s.label === seatLabel),
        );
        if (foundSeat > -1) {
          allCartSeats[product.id].splice(foundSeat, 1);
        }
        setItem(CART_SEATS, JSON.stringify(allCartSeats));
      },
      getCartSeats: () => {
        const cartSeats = getItem(CART_SEATS);
        return cartSeats ? JSON.parse(cartSeats) : {};
      },
      getAllProductSeatsLabels: (product: IProductInfo) => {
        const allCartSeats = actions.getCartSeats();
        const allLabels = flatten(
          flatten(allCartSeats[product.id] || []).map(({ seats = [] }) =>
            seats.map(({ label }) => label),
          ),
        );
        return allLabels;
      },
      setProductSeatings: (products: IProductInfo[]) => {
        const oldProductSeatings = actions.getProductSeatings() || {};

        const productsSeatings = products.reduce((current, product) => {
          const currentCopy = { ...current };
          currentCopy[product.id] = product.reservedSeating;
          return currentCopy;
        }, {});
        setItem(PRODUCTS_SEATINGS, JSON.stringify({ ...oldProductSeatings, ...productsSeatings }));
      },
      getProductSeatings: () => {
        const productSeatings = getItem(PRODUCTS_SEATINGS);
        let result = {};
        if (productSeatings) {
          try {
            result = JSON.parse(productSeatings);
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        return result;
      },
      setPreviousRoute: (route) => {
        setItem(PREV_ROUTE, route);
      },
      getPreviousRoute: () => getItem(PREV_ROUTE),
      addTimeSlottedCart: (productId) => {
        const productsIds: number[] = JSON.parse(getItem(TIMESLOTTED_PRODUCTS) || '[]');
        if (productsIds.indexOf(productId) < 0) {
          productsIds.push(productId);
          setItem(TIMESLOTTED_PRODUCTS, JSON.stringify(productsIds));
        }
      },
      removeTimeSlottedCart: (productId) => {
        const productsIds: number[] = JSON.parse(getItem(TIMESLOTTED_PRODUCTS) || '[]');
        const index = productsIds.indexOf(productId);
        if (index > -1) {
          productsIds.splice(index, 1);
          setItem(TIMESLOTTED_PRODUCTS, JSON.stringify(productsIds));
        }
      },
      isTimeSlottedInCart: (productId) => {
        const productsIds: number[] = JSON.parse(getItem(TIMESLOTTED_PRODUCTS) || '[]');
        return productsIds.indexOf(productId) > -1;
      },
      clearTimeSlottedInCart: () => {
        setItem(TIMESLOTTED_PRODUCTS, JSON.stringify([]));
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  useEffect(() => {
    (async () => {
      const cartId = getItem(CART_ID) || initialState?.cartId;
      const allCartSeats = actions.getCartSeats();
      setState((oldState) => ({
        ...oldState,
        cartId,
      }));
      if (cartId) {
        try {
          const cartDetails = await getCartItems(cartId);
          const cartBookedDate = cartDetails.booked_date;
          const today = new Date();
          const todayString = yyyyMMDD(today);
          const absBookingDate = new Date(`${cartBookedDate}T00:00:00`);
          const absToday = new Date(`${todayString}T00:00:00`);
          if (absBookingDate >= absToday) {
            setState((oldState) => ({
              ...oldState,
              cart: {
                ...oldState.cart,
                items: [
                  ...(cartDetails.items?.map((item, index) => {
                    const itemCopy = { ...item };
                    itemCopy.seats = allCartSeats[item.product_id]
                      ? allCartSeats[item.product_id][index]?.seats || []
                      : [];
                    itemCopy.units = item.no_of_adults;
                    return itemCopy;
                  }) || []),
                ],
                total: cartDetails.total,
                tax: cartDetails.tax,
              },
              isUpdatingCart: false,
              cartId: cartDetails.id,
            }));
          } else if (router.asPath === '/') {
            actions.clearCartStorage();
            actions.clearState(false);
          } else {
            actions.setErrorPopup({
              open: true,
              text: 'Your cart has expired.',
              callback: () => {
                actions.clearCartStorage();
                actions.clearState();
                router.push('/');
              },
            });
          }
        } catch (error: unknown | any) {
          if (router.asPath === '/') {
            actions.clearCartStorage();
            actions.clearState(false);
          } else {
            const message =
              error?.response?.data?.message ?? 'Something went wrong. Please try again.';
            actions.setErrorPopup({
              open: true,
              text:
                message.includes('Cart') && message.includes('was not found')
                  ? 'Your cart has expired.'
                  : message,
              callback: () => {
                actions.clearCartStorage();
                actions.clearState();
                router.push('/');
              },
            });
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.params.category) {
      actions.setCategory(state.params.category);
    }
    if (router.query.date) {
      actions.setDate(new Date(router.query.date as string));
    }
    if (state.params.confirmation || router.query.confirmation) {
      actions.setConfirmation(state.params.confirmation || (router.query.confirmation as string));
    }
    setItem(CART_ID, state.cartId ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    router.query.date,
    router.query.confirmation,
    state.params.category,
    state.params.confirmation,
    state.cartId,
  ]);

  const value = useMemo(() => ({ state, actions }), [state, actions]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
AppContextProvider.defaultProps = { initialState: {} };

export const useAppContext: () => IAppContext = () => {
  const configContext = useContext(AppContext);
  if (!configContext) throw new Error('No AppContext.Provider found when calling useAppContext.');
  return configContext;
};
